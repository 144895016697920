html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.main .title {
  z-index: 2;
  color: #08fdd8;
  font-size: 50px;
  width: auto;
  display: block;
  text-align: left;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  margin: 0;
}
.main .title-wrapper {
  position: relative;
}
.main .title-wrapper:before,
.main .title-wrapper:after {
  content: "<title>";
  position: absolute;
  color: gray;
  font-size: 24px;
  margin-top: -1.5em;
  margin-left: -1em;
  font-family: "Courier New", Courier, monospace;
  display: block;
}

.main .title-wrapper:after {
  content: "</title>";
  margin-top: 0;
  bottom: -1.5em;
}

section {
  padding: 2em;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

section .title {
  color: #05a38c;
}

section:nth-child(odd) {
  background: #252627;
  color: #ccc;
}

section:nth-child(odd) .title {
  color: #08fdd8;
}
